<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-11-02 19:00:09
 * @LastEditors: 郭长生
 * @LastEditTime: 2024-02-29 20:35:56
-->
<template>
  <div>
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item>{{ $t("route.region") }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-show="total != 0" class="regionList">
      <el-row>
        <el-col :sm="12" :md="8">
          <el-table ref="singleTable" id="regionLeft" highlight-current-row :data="areaList" style="width: 100%"
            @current-change="handleRow" :class="areaList.length < 12 ? 'lessDataTable' : null">
            <el-table-column :label="$t('region.area')" prop="area_name" />
            <el-table-column align="right" width="240px">
              <template #header>
                <el-dropdown style="marginRight:20px" trigger="click">
                  <span class="el-dropdown-link" style="color:#409eff">
                    {{
                      params.order_by == "created_at DESC"
                      ? $t("user.timecalorders")
                      : $t("user.alphabeticalorder")
                    }}
                    <i class="el-icon-arrow-down" />
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="Setsort('created_at DESC')">{{
                        $t("user.timecalorders")
                      }}</el-dropdown-item>
                      <el-dropdown-item @click="Setsort('area_name ASC')">{{
                        $t("user.alphabeticalorder")
                      }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <el-button type="primary" @click="createRegion" @keyup.space.prevent.native
                  @keydown.enter.prevent.native>{{ $t("system.create") }}</el-button>
              </template>
              <template #default="scope">
                <el-popover placement="bottom" :width="100" trigger="click" class="regionPopover">
                  <template #reference>
                    <span style="cursor: pointer">&emsp;&emsp; ... &emsp;&emsp;</span>
                  </template>
                  <el-button type="text" @click.stop="getEditRegion(scope.row)" size="mini" @keyup.space.prevent.native
                    @keydown.enter.prevent.native>{{ $t("system.rename") }}</el-button><br />
                  <el-button type="text" @click.stop="delRegion(scope.row)" size="mini" @keyup.space.prevent.native
                    @keydown.enter.prevent.native>{{ $t("system.delete") }}</el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination background v-if="total / params.pageSize > 1" :page-size="params.pageSize"
              :current-page="currentPage" :total="total" @current-change="handleCurrentChange"
              layout="total, prev, pager, next" style="text-align: right">
            </el-pagination>
            <div v-else class="noPage">
              {{ $t("system.page").replace("XX", total) }}
            </div>
          </div>
        </el-col>
        <el-col :sm="12" :md="16" style="padding-right: 24px">
          <el-table :data="roomList" style="width: 100%; margin-left: 24px"
            :class="roomList.length < 12 ? 'lessDataTable' : null" @cell-click="cellclick">
            <el-table-column :label="$t('region.room')" prop="RoomName">
              <template #default="{ row }"><span @click="jumpToDetail(row)">{{
                row.RoomName
              }}</span></template>
            </el-table-column>
            <el-table-column align="right" width="350">
              <template #header>
                <el-dropdown style="marginRight:20px" trigger="click">
                  <span class="el-dropdown-link" style="color:#409eff">
                    {{
                      roomParams.order_by == "created_at DESC"
                      ? $t("user.timecalorders")
                      : $t("user.alphabeticalorder")
                    }}
                    <i class="el-icon-arrow-down" />
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="Setroomsort('created_at DESC')">{{ $t("user.timecalorders")
                      }}</el-dropdown-item>
                      <el-dropdown-item @click="Setroomsort('room_name ASC')">{{
                        $t("user.alphabeticalorder")
                      }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <el-button @click="createRoom" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                  $t("system.create") }}</el-button>
              </template>
              <template #default="scope">
                <span @click.stop="goDisinfect(scope.row)" class="tempText">{{
                  $t("disinfect.detail")
                }}</span>
                <span @click.stop="getEditRoom(scope.row)" class="tempText"
                  :class="[currentLanuage == 'es' ? 'es' : '']">{{ $t("system.rename") }}</span>
                <span @click.stop="delRoom(scope.row)" class="tempText">{{
                  $t("system.delete")
                }}</span>
              </template>
            </el-table-column>
            <template #empty>
              <div class="tableNodata">
                <img :src="totalImg.nodata" width="62" />
                <p>{{ $t("region.noRoom") }}</p>
                <el-button type="primary" @click="createRoom" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                  $t("region.createNow") }}</el-button>
              </div>
            </template>
          </el-table>
          <div class="pageBox" style="margin-left: 24px">
            <el-pagination background v-if="roomTotal / roomParams.pageSize > 1" :page-size="roomParams.pageSize"
              :current-page="roomCurrentPage" :total="roomTotal" :page-sizes="[16, 32, 64, 128, 200]"
              @current-change="_handleCurrentChange" @size-change="handleSizeChange"
              layout="total, prev, pager, next, sizes, jumper" style="text-align: right; padding-right: 24px">
            </el-pagination>
            <div v-else class="noPage">
              {{ $t("system.page").replace("XX", roomTotal) }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-show="total == 0" class="contentWrapper">
      <div class="nodata">
        <img :src="totalImg.nodataArea" width="76" />
        <p>{{ $t("region.noArea") }}</p>
        <el-button type="primary" @click="createRegion" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
          $t("region.createNow") }}</el-button>
      </div>
    </div>
    <el-dialog :title="isEdit ? $t('region.changeArea') : $t('region.createArea')" v-model="visible">
      <el-form ref="regionForm" :rules="rules" :model="regionValue" style="width: 400px; margin-left: 50px">
        <el-form-item prop="areaName">
          <el-input v-model="regionValue.areaName" @input="areaNameinput" :maxlength="22"
            :placeholder="$t('region.enterArea')" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="visible = false" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
            $t("system.cancel") }}</el-button>
          <el-button type="primary" @click="handleSubmit" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
            $t("system.determine") }}</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog :title="roomIsEdit ? $t('region.changeRoom') : $t('region.createRoom')" v-model="roomVisible">
      <el-form ref="roomForm" :rules="roomRules" :model="roomValue" style="width: 400px; margin-left: 50px">
        <el-form-item prop="roomName">
          <el-input v-model="roomValue.roomName" @input="roomNameinput" :maxlength="22"
            :placeholder="$t('region.createRoom')" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="roomVisible = false" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
            $t("system.cancel") }}</el-button>
          <el-button type="primary" @click="_handleSubmit" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
            $t("system.determine") }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs,
  unref
} from "vue";
import { taskList } from "@/apis/robot";
import {
  regionList,
  delRegion,
  editRegion,
  createRegion,
  regionInfo,
  roomList,
  delRoom,
  editRoom,
  createRoom,
  roomInfo
} from "@/apis/region";
// import { Document } from '@element-plus/icons'
import { ElMessageBox, ElMessage, ElForm } from "element-plus";
import { useRouter } from "vue-router";
import moment from "moment";
import { getHM, totalImg, setRobotName } from "@/utils/robot";
import { stat } from "fs";
import { getLanguage } from "@/utils/cookies";
import i18n from "@/locales/index";
let _t = i18n.global.t;

export default {
  components: {},
  setup() {
    const regionForm = ref(ElForm);
    const roomForm = ref(ElForm);
    const router = useRouter();
    const singleTable = ref(null);
    const state = reactive({
      currentRow: null,
      params: {
        page: 1,
        pageSize: 16,
        limit: true,
        order_by: "created_at DESC"
      },
      roomParams: {
        page: 1,
        pageSize: 16,
        limit: true,
        order_by: "created_at DESC",
        areaid: ""
      },
      totalImg: totalImg(),
      getHM: getHM,
      setRobotName: setRobotName,
      currentPage: 1,
      total: 0,
      areaList: [],
      roomList: [],
      visible: false,
      isEdit: false,
      rules: {
        areaName: [
          { required: true, message: _t("region.enterArea"), trigger: "blur" }
        ]
      },
      regionValue: {
        areaName: ""
      },
      roomid: "",
      roomCurrentPage: 1,
      roomTotal: 0,
      roomVisible: false,
      roomIsEdit: false,
      roomRules: {
        roomName: [
          { required: true, message: _t("region.enterRoom"), trigger: "blur" }
        ]
      },
      roomValue: {
        roomName: ""
      },
      areaNameinput(e) {
        if (e[0] == " ") {
          e = e.substr(1);
        }
        state.regionValue.areaName = e;
        console.log(state.regionValue.areaName);
      },
      roomNameinput(e) {
        if (e[0] == " ") {
          e = e.substr(1);
        }
        state.roomValue.roomName = e;
        console.log(state.roomValue.roomName);
      },
      handleFocusRegion(val) {
        console.log(val);
      },
      getList() {
        regionList(state.params, true).then(res => {
          if (res.code == 200) {
            state.areaList = res.data.lists;
            state.total = res.data.total;
            state.currentPage = res.data.page;
            let arr = res.data.lists;
            if (arr && arr.length > 0) {
              state.roomParams.areaid = arr[0].ID;
              state.getRoomList();
              state.currentRow = res.data.lists[0];
              singleTable.value.setCurrentRow(res.data.lists[0]);
            }
          }
        });
      },
      Setroomsort(sortmode) {
        state.roomParams.order_by = sortmode;
        state.getRoomList();
      },
      Setsort(sortmode) {
        // if (state.params.order_by == "created_at DESC") {
        //   state.params.order_by = "area_name ASC";
        // } else {
        //   state.params.order_by = "created_at DESC";
        // }
        state.params.order_by = sortmode;
        state.getList();
      },
      getRoomList() {
        roomList(state.roomParams, true).then(res => {
          if (res && res.code == 200) {
            state.roomList = res.data.lists;
            state.roomTotal = res.data.total;
            state.roomCurrentPage = res.data.page;
          }
        });
      },
      handleRow(val) {
        state.currentRow = val;
        state.roomParams.areaid = val.ID;
        state.getRoomList();
      },
      createRoom() {
        state.roomIsEdit = false;
        state.roomVisible = true;
        state.roomValue.roomName = "";
        nextTick(() => {
          const form = unref(roomForm);
          form.resetFields();
        });
      },
      goDisinfect(item) {
        let roomData = [state.roomParams.areaid, item.ID];
        router.push({
          name: "disinfect",
          params: { roomData: roomData }
        });
      },
      getEditRoom(item) {
        state.roomIsEdit = true;
        state.roomVisible = true;
        state.roomid = item.ID;
        nextTick(() => {
          const form = unref(roomForm);
          form.resetFields();
        });
        let params = {
          roomid: item.ID,
          areaid: state.roomParams.areaid,
          pageSize: 15,
          page: 1
        };
        roomInfo(params)
          .then(res => {
            if (res.code == 200) {
              state.roomValue.roomName = res.data.room_name;
            }
          })
          .catch(err => {
            ElMessage.closeAll();
            ElMessage({
              type: "error",
              message: err.msg
            });
          });
      },
      cellclick(row) {
        if (typeof row.ID === "number") {
          state.jumpToDetail(row);
        }
      },
      jumpToDetail(obj) {
        console.log("查询当前数据", obj);
        sessionStorage.setItem("roomNameCopy", obj.RoomName);
        if (sessionStorage.getItem("roomNameCopy")) {
          sessionStorage.removeItem("roomNameCopy");
          sessionStorage.removeItem("currentRowCopy");
        }
        // router.push(`/region/regionManageroom/${obj.ID}/${state.currentRow.area_name}`)
        router.push({
          path: "regionManageroom",
          query: {
            roomid: obj.ID,
            Roomname: obj.RoomName,
            Areaname: state.currentRow.area_name
          }
        });
      },
      addRoom() {
        state.roomValue.areaid = state.roomParams.areaid;
        createRoom(state.roomValue)
          .then(res => {
            if (res.code == 200) {
              state._handleSearch();
              state.roomVisible = false;
              ElMessage({
                type: "success",
                message: _t("system.createSuccess")
              });
            } else if (res.code == 100012) {
              ElMessage({
                type: "error",
                message: _t("disinfect.fjmycz")
              });
            }
          })
          .catch(err => {
            ElMessage.closeAll();
            ElMessage({
              type: "error",
              message: _t("disinfect.fjmycz")
            });
          });
      },
      updateRoom() {
        let data = {
          areaid: state.roomParams.areaid,
          roomid: state.roomid,
          params: state.roomValue
        };
        console.log(data);
        editRoom(data)
          .then(res => {
            if (res.code == 200) {
              state._handleSearch();
              state.roomVisible = false;
              ElMessage({
                type: "success",
                message: _t("system.changeSuccess")
              });
            } else if (res.code == 100012) {
              ElMessage({
                type: "error",
                message: _t("disinfect.fjmycz")
              });
            }
          })
          .catch(err => {
            ElMessage.closeAll();
            ElMessage({
              type: "error",
              message: _t("disinfect.fjmycz")
            });
          });
      },
      handleSizeChange(val) {
        state.roomParams.pageSize = val;
        state.getRoomList();
      },
      _handleCurrentChange(page) {
        state.roomParams.page = page;
        state.getRoomList();
      },
      _handleSearch() {
        state.roomParams.page = 1;
        state.getRoomList();
      },
      getRoomDetail(item) {
        sessionStorage.roomid = item.ID;
        sessionStorage.roomName = item.RoomName;
        router.push(`/region/roomInfo/${item.ID}`);
      },
      delRoom(item) {
        ElMessageBox.confirm(_t("region.modalTip1"), _t("region.delRoom"), {
          confirmButtonText: _t("system.delete"),
          cancelButtonText: _t("system.cancel"),
          type: "warning"
        }).then(() => {
          let params = {
            area_id: state.roomParams.areaid,
            room_id: item.ID,
            pageSize: 1000,
            page: 1,
            status: "start"
          };
          delRoom(params).then(res => {
            if (res.code == 200) {
              ElMessage({
                type: "success",
                message: _t("system.deleteSuccess")
              });
              state._handleSearch();
            } else {
              ElMessage({
                type: "error",
                message: _t("disinfect.rwzwfsc")
              });
              state._handleSearch();
            }
          });
        });
      },
      _handleSubmit() {
        const form = unref(roomForm);
        console.log("form==>", form);
        form.validate(async valid => {
          if (valid) {
            if (state.roomIsEdit) {
              state.updateRoom();
            } else {
              state.addRoom();
            }
          }
        });
      },
      createRegion() {
        state.isEdit = false;
        state.visible = true;
        state.regionValue.areaName = "";
        nextTick(() => {
          const form = unref(regionForm);
          form.resetFields();
        });
      },
      getEditRegion(item) {
        state.isEdit = true;
        state.visible = true;
        state.areaid = item.ID;
        nextTick(() => {
          const form = unref(regionForm);
          form.resetFields();
        });
        regionInfo(item.ID).then(res => {
          if (res.code == 200) {
            state.regionValue.areaName = res.data.AreaName;
          }
        });
      },
      addRegion() {
        createRegion(state.regionValue)
          .then(res => {
            console.log("res>>>", res);
            if (res.code == 200) {
              state.handleSearch();
              state.visible = false;
              ElMessage({
                type: "success",
                message: _t("system.createSuccess")
              });
            } else if (res.code == 100011) {
              ElMessage({
                type: "error",
                message: _t("disinfect.qymycz")
              });
            }
          })
          .catch(err => {
            ElMessage.closeAll();
            ElMessage({
              type: "error",
              message: _t("disinfect.qymycz")
            });
          });
      },
      updateRegion() {
        let data = {
          areaid: state.areaid,
          params: state.regionValue
        };
        editRegion(data)
          .then(res => {
            if (res.code == 200) {
              state.handleSearch();
              state.visible = false;
              ElMessage({
                type: "success",
                message: _t("system.changeSuccess")
              });
            } else if (res.code == 100011) {
              ElMessage({
                type: "error",
                message: _t("disinfect.qymycz")
              });
            }
          })
          .catch(err => {
            ElMessage.closeAll();
            ElMessage({
              type: "error",
              message: _t("disinfect.qymycz")
            });
          });
      },
      handleCurrentChange(page) {
        state.params.page = page;
        state.getList();
      },
      handleSearch() {
        // state.params.page = 1
        state.getList();
      },
      getRegionDetail(item) {
        sessionStorage.areaid = item.ID;
        sessionStorage.areaName = item.area_name;
        router.push(`/region/roomManage/${item.ID}`);
      },
      delRegion(item) {
        state.roomParams.areaid = item.ID;
        roomList(state.roomParams).then(res => {
          if (res.code == 200) {
            let data;
            data = res.data.lists;
            if (data.length == 0) {
              ElMessageBox.confirm(
                _t("region.modalTip3"),
                _t("region.delArea"),
                {
                  confirmButtonText: _t("system.delete"),
                  cancelButtonText: _t("system.cancel"),
                  type: "warning"
                }
              ).then(() => {
                delRegion(item.ID).then(res => {
                  if (res.code == 200) {
                    ElMessage({
                      type: "success",
                      message: _t("system.deleteSuccess")
                    });
                    state.handleSearch();
                  }
                });
              });
            } else {
              ElMessageBox.alert(_t("region.modalTip2"), _t("region.delArea"), {
                confirmButtonText: _t("system.determine")
              });
            }
          }
        });
      },
      handleSubmit() {
        const form = unref(regionForm);
        form.validate(async valid => {
          if (valid) {
            if (state.isEdit) {
              state.updateRegion();
            } else {
              state.addRegion();
            }
          }
        });
      }
    });
    const currentLanuage = getLanguage("languageKey");
    onMounted(() => {
      state.getList();
    });
    return {
      ...toRefs(state),
      regionForm,
      roomForm,
      singleTable,
      currentLanuage
    };
  }
};
</script>
<style lang="scss">
.regionPopover {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  .el-popper__arrow::before {
    display: none !important;
  }
}

.el-popover.el-popper {
  min-width: 100px !important;
  text-align: center;

  span {
    color: #000 !important;
  }
}

.regionList {
  .el-button--text {
    color: #1890ff !important;
  }

  .has-gutter tr th {
    background: #fafafa !important;
  }

  .el-table__row {
    cursor: pointer;
  }

  .el-table {
    min-height: 70vh !important;

    .el-table__body tr td {
      height: 53px !important;
      padding: 0 !important;
      border-right: 4px solid transparent;
    }

    .current-row>td {
      background-color: #e7f7ff;
    }

    .current-row td:last-child {
      border-right: 4px solid #0f90ff;
    }

    .cell {
      padding: 0 20px !important;
    }
  }

  .lessDataTable::before {
    background: #fff !important;
  }

  .el-pagination {
    width: 100%;
    background: #fff;
    padding: 46px 0 28px;
  }
}
</style>
<style lang="scss" scoped>
.regionList {
  position: relative;
  margin: 24px;

  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
  }

  h3 {
    margin: 0 0 16px;
  }

  .regionItem {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 200px;
    width: 90%;
    padding: 10px !important;
    margin: 0 20px 30px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;

    p:nth-child(1) {
      font-size: 20px;
      font-weight: bold;
      word-break: break-all;
    }

    p:nth-child(2) {
      font-size: 26px;
      font-weight: bold;
    }

    cursor: pointer;

    .robotImg {
      position: absolute;
      height: 160px;
      top: 30px;
      left: 40px;
    }

    p {
      img {
        width: 24px;
        margin: -4px 4px 0 0;
      }

      span {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .tempText {
    color: #1890ff;

    &:nth-child(2) {
      padding: 0 64px;
    }

    &.es:nth-child(2) {
      padding: 0 30px;
    }
  }
}

.nodata {
  text-align: center;
  margin-top: 100px;
}

.pageBox {
  height: 102px;
  background: #fff;
  width: 100%;

  .noPage {
    text-align: right;
    padding: 46px 24px 28px;
    font-size: 14px;
    color: #606266;
  }
}
</style>
